import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import "./index.scss";
import { dataLink, dataSub } from '../FakeDatas';
import { navApp } from './navApp';

import { graphql, useStaticQuery } from 'gatsby';
import SubMenu from "../SubMenu";
import { useWindowSize } from "../../functions/window-size";
import { scrollFromTop } from "../../functions/scroll-from-top";

const Nav = ({ }) => {

  const img = useStaticQuery(graphql`
  {
      logo: file(relativePath: {eq: "favicon.png"}, publicURL: {}) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            srcWebp
          }
        }
      }
    }
  `);

  const windowParams = useWindowSize();

  React.useEffect(() => {
    document.addEventListener('scroll', () => {
      if(typeof window == "undefined" || windowParams.width < 768) {
        return;
      }
      else {
        if(window.scrollY > 100 && windowParams.width > 768) {
          document.querySelector('.nav').classList.add('scroll-fixed');
          document.querySelector('#contactUs').classList.add('scroll-fixed');
        }
        else {
          document.querySelector('.nav').classList.remove('scroll-fixed');
          document.querySelector('#contactUs').classList.remove('scroll-fixed');
        }
      }
    })
    document.addEventListener('resize', () => {
      if(windowParams.width < 768) {
        document.querySelector('.nav').classList.remove('scroll-fixed');
        document.querySelector('#contactUs').classList.remove('scroll-fixed');
      }
    })
  });

  return (
    <div className="nav">
        <div 
            className="nav-logo" 
            style={{ backgroundImage: 'url'+'('+img.logo.childImageSharp.fixed.srcWebp+')' }}
        >
          <Link to="/" className="absolute-link" title="Accueil"></Link>
        </div>
        <div className="container nav-links">
          {
            dataLink.map((linkObj, key) => (
              <div key={key} className="nav-elem">
                {
                  linkObj.type == 'anchor'
                  &&
                  <AnchorLink
                    to={linkObj.link || "#"}
                    className="nav-link"
                    key={linkObj.title}
                    title={linkObj.title}
                  >
                    <div className="ctn-link">
                        {linkObj.title}
                    </div>
                  </AnchorLink>
                }
                {
                  linkObj.type == undefined
                  &&
                  <Link
                    to={linkObj.link || "#"}
                    className="nav-link"
                    key={linkObj.title}
                    title={linkObj.title}
                  >
                    <div className="ctn-link">
                        {linkObj.title}
                    </div>
                  </Link>
                }
                {dataSub[linkObj.title] && <SubMenu 
                  datasSub={dataSub[linkObj.title]} 
                  key={linkObj.leftTitle}
                />}
              </div>
            ))
          }
        </div>
        <div
          className="nav-contact"
          onClick={navApp.handleContactOpened}
        >
          Contact
        </div>
        <button type="button" className="btn-menu" onClick={navApp.handleMenuOpened}>
            Menu
        </button>
        <div id="ctn-menu_mobile" >
          <Link to="/" className="link-mobile" title="Accueil">Accueil</Link>
          <Link to="#" className="link-mobile" title="Services">Services</Link>
          <Link to="#" className="link-mobile" title="Protfolio">Protfolio</Link>
          <Link to="#" className="link-mobile" title="Agence">Agence</Link>
        </div>
    </div>
  )
}

export default Nav
