import React from "react";
import PropTypes from "prop-types";

import './index.scss';
import Nav from '../Nav';

// TODO Ajouter un "header-not-display" pour les résolutions non prises en charges

const Header = ({  }) => {
    return (
        <header>
            <Nav />
        </header>
    );
};

Header.propTypes = {

};

Header.defaultProps = {

};

export default Header;