import { manage_scroll } from "../../functions/manage-scroll";
import { appMaker } from "../ProjectMaker/makersFunc";

export const navApp = {

    manage_cases: (() => {
        ////////////////////
        // APPMAKER DEVIS //
        ////////////////////
        if(document.querySelector('.ctn-maker') != null) {
            if(
                document.querySelector('.btn-menu').classList.contains('opened')
                ||
                document.getElementById('contactUs').classList.contains('contactUs-opened')
            ) {
                appMaker.stopEventListeners();
            }
            if(
                !document.querySelector('.btn-menu').classList.contains('opened')
                &&
                !document.getElementById('contactUs').classList.contains('contactUs-opened')
            ) {
                appMaker.startEventListeners();
            }
        }
    }),

    handleContactOpened: () => {
        document.getElementById('contactUs').classList.contains('contactUs-opened') && document.forms['contact-mini'].reset();
        document.getElementById('contactUs').classList.toggle('contactUs-opened');
        manage_scroll();
        navApp.manage_cases();
    },

    handleMenuOpened: () => {
        document.getElementById('ctn-menu_mobile').classList.toggle('opened');
        document.querySelector('.btn-menu').classList.toggle('opened');
        navApp.manage_cases();
    }
}