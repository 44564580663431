import React from "react";

import './index.scss';

const Modale = () => {
    return (
        <div id="modale">
            <div id="modale-container">
                <div id="modale-content" />
            </div>
        </div>
    )
}

export default Modale;