import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from 'gatsby';
import { globalHistory } from "@reach/router";

import './index.scss';

// TODO Ajouter un "footer-not-display" pour les résolutions non prises en charges

const Footer = ({  }) => {
    
    const icon = useStaticQuery(graphql`{
        allFile(filter: {relativeDirectory: {eq: "social"}}) {
            nodes {
                publicURL
                name
            }
        }
      }`
    ).allFile.nodes;

    const cities = [
        {
            name: `Marseille`,
            address: `124 Rue de Crimée
            13003, Marseille`,
        },
        {
            name: `Rouen`,
            address: `8 Rue Nicolas Mesnager
            76000, Rouen`,
        },
        {
            name: `Le Puy en Velay`,
            address: `31 Route du Couderc 
            43000, Le Puy en Velay`,
        },
    ]
    
    return (
        <footer className="main-footer">
            {globalHistory.location.pathname != '/project' && <div className="container">
                <div className="footer-cities">
                    {cities.map((city, key) => {
                        return (
                            <div className="city" key={key}>
                                <div className="city-name">
                                    {city.name}
                                </div>
                                <div className="city-address">
                                    {city.address}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>}
            <div className="footer-links">
                <Link to="/services" className="footer-link" title="Services">
                    Services
                </Link>
                <Link to="/#portfolio" className="footer-link" title="Portfolio">
                    Portfolios
                </Link>
                <Link to="#" className="footer-link" title="Hébergement">
                    Hebergement
                </Link>
                <Link to="#" className="footer-link" title="Sitemap">
                    Sitemap
                </Link>
            </div>
            <div className="footer-social">
                <span className="language">Francais</span>
                <div className="footer-social_links">
                    {
                        icon.map((iconObj, key) => (
                            <a href="#" target="_blank" className="social-link" key={key} title={iconObj.name}>
                                <img 
                                    src={iconObj.publicURL}
                                    alt={iconObj.name}
                                    className="social-icon"
                                />
                            </a>
                        ))
                    }
                    
                </div>
                <span>	&copy; 2020 Emeka</span>
            </div>            
        </footer>
    );
};

Footer.propTypes = {

};

Footer.defaultProps = {

};

export default Footer;