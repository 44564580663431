import React from "react";
import PropTypes from "prop-types";
import { globalHistory } from "@reach/router";

import "./index.scss";

import Header from "../Header";
import Footer from "../Footer";
import PopinDevis from "../PopinDevis";
import Modale from "../Modale";
import ContactUs from "../ContactUs";

const Layout = ({ children }) => {

  const [path, setPath] = React.useState(globalHistory.location.pathname);

  React.useEffect(() => {
    setPath(globalHistory.location.pathname);
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if (window.location.hash && isChrome) {
        setTimeout(function () {
            var hash = window.location.hash;
            window.location.hash = "";
            window.location.hash = hash;
        }, 300);
    }
  });

  return (
    <>
      <Header/>
      <main>{children}</main>
      {['/'].indexOf(globalHistory.location.pathname) > -1 && <PopinDevis />}
      <Modale />
      <ContactUs />
      <Footer/>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {

};

export default Layout
