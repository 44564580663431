export const dataLink:Array<{title:string, link:string, type?:string}> = [
    {
      title: 'Accueil',
      link: '/',
    },
    {
      title: 'Services',
      link: '/services',
    },
    {
      title: 'Portfolios',
      link: '/#portfolio',
      type: 'anchor'
    },
    {
      title: 'Agence',
      link: '',
    }
  ];

export const dataSub = {
  'Services': [
    {
      rightTitle: 'WebDesign',
      leftTitle: 'WebDesign',
      link: '/services',
      content: 
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et si rchitecto beatae vitae dicta sunt psam voluptatem quia explicabo.',
    },
    {
      rightTitle: 'SEO / Référencement ',
      leftTitle: 'SEO / Référencement',
      link: '#',
      content: 
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et si rchitecto beatae vitae dicta sunt psam voluptatem quia explicabo.',
    },
    {
      rightTitle: 'Google / FB Ads',
      leftTitle: 'Google / FB Ads',
      link: '#',
      content: 
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et si rchitecto beatae vitae dicta sunt psam voluptatem quia explicabo.',
    },
    {
      rightTitle: 'Branding',
      leftTitle: 'Branding',
      link: '#',
      content: 
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et si rchitecto beatae vitae dicta sunt psam voluptatem quia explicabo.',
    },
    {
      rightTitle: 'Hébergement',
      leftTitle: 'Hébergement',
      link: '#',
      content: 
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et si rchitecto beatae vitae dicta sunt psam voluptatem quia explicabo.',
    },
  ],
}