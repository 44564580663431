import React from 'react';
import { sendRequest } from '../../functions/ajax-manage';
import { getFormDatas } from '../../functions/form-manage';

import './index.scss';

const regex:RegExp = new RegExp(/[A-Za-z0-9&~"#'{([-|`_\\^@)°\]=+}$£¤%*µ,?;.:\/!§]{8,}/g);

const ContactUs = () => {

    let i = 0;

    const handleSubmit = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        sendRequest(
            'https://emeka.fr/back/contact',
            'POST',
            {
                action: 'contact',
                datas: {
                    ...getFormDatas('contact-mini')
                },
            }
        )
        .then(res => res.json())
        .then(function(response) {
            (result) => {
                document.forms['contact-mini'].reset();
                document.querySelector('#contactUs').classList.remove('contactUs-opened');
            },
            // Remarque : il faut gérer les erreurs ici plutôt que dans
            // un bloc catch() afin que nous n’avalions pas les exceptions
            // dues à de véritables bugs dans les composants.
            (error) => {
                document.forms['contact-mini'].reset();
                document.querySelector('#contactUs').classList.remove('contactUs-opened');
            }
        }).catch(function(error) {
            document.forms['contact-mini'].reset();
            document.querySelector('#contactUs').classList.remove('contactUs-opened');
            // TODO Add log error message on div#error
        });
    }

    return (
        <div id="contactUs">
            <div className="ctn-contactUs">
                <h2>Faîtes nous signe ici!</h2>
                <form id="contact-mini" action="#" method="post" className="contactUs-form" >
                    <ul className="contactUs-form-ul">
                        <li className="contactUs-form-li">
                            <label htmlFor="firstName">{++i}</label>
                            <input type="text" id="firstName" name="firstName" placeholder="Votre prénom" required/>
                        </li>
                        <li className="contactUs-form-li">
                            <label htmlFor="lastName">{++i}</label>
                            <input type="text" id="lastName" name="lastName" placeholder="Votre nom" required/>
                        </li>
                        <li className="contactUs-form-li">
                            <label htmlFor="email">{++i}</label>
                            <input type="email" id="email" name="email" placeholder="Votre adresse mail" required/>
                        </li>
                        <li className="contactUs-form-li">
                            <label htmlFor="comment">{++i}</label>
                            <textarea id="comment" name="comment"placeholder="Décrivez votre demande" rows={10} required resizeable="false"></textarea>
                        </li>
                    </ul>
                    <button type="submit" onClick={handleSubmit}>Envoyer</button>
                </form>
            </div>
        </div>
    )
}

export default ContactUs;