import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import "./index.scss";
import { graphql, useStaticQuery } from 'gatsby';

const SubMenu = ({ datasSub }) => {
  
    const img = useStaticQuery(graphql`
      {
        arrow: file(relativePath: {eq: "banner/right-arrow.svg"}) {
          publicURL
          name
        }
      }
    `)
    
  return (
    <div className="subMenu">
        {
            datasSub.map((data:any, key:number) => (
                <>
                    <Link to={ data.link || "#" } className="subMenu-link" key={data.rightTitle} title={data.link || "#"}>
                        {data.leftTitle}
                    </Link>
                    <div 
                      className="subMenu-content"
                      key={key}
                    >
                        <h3>{data.rightTitle}</h3>
                        <p>{data.content}</p>
                        <div 
                            className="arrow"
                            style={{ backgroundImage: 'url'+'('+img.arrow.publicURL+')' }}
                        >
                        </div>
                    </div>
                </>
            ))
        }
    </div>
  )
}

SubMenu.propTypes = {
  datasSub: PropTypes.arrayOf(
    PropTypes.shape({
      rightTitle: PropTypes.string,
      leftTitle: PropTypes.string,
      link: PropTypes.string,
      content: PropTypes.string,
    }).isRequired,
  ).isRequired,
}

export default SubMenu

