export const manage_scroll = function() {
    // ALL ENABLES
    if(document.querySelector('.contactUs-opened') == null) {
        enableScroll();
        return;
    }
    // ALL DISABLES
    if(window.innerWidth <= 768 && document.querySelector('.contactUs-opened') != null) {
        disableScroll();
        return;
    }
};

function disableScroll() {
    document.querySelector('body').classList.add('no-scroll');
    document.querySelector('main').classList.add('no-scroll');
}

function enableScroll() {
    document.querySelector('body').classList.remove('no-scroll');
    document.querySelector('main').classList.remove('no-scroll');
}