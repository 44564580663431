import React from "react";

declare type WindowSizeInterface = {
    width:number,
    height:number
};

export const useWindowSize = function():WindowSizeInterface {
    const [windowSize, setWindowSize] = React.useState({
        width: undefined,
        height: undefined,
    });
    
    React.useEffect(():any => {
        function handleResize():void {
            setWindowSize({
                width: window.outerWidth,
                height: window.outerHeight,
            });
        }
        
        window.addEventListener("resize", handleResize);
        
        handleResize();
        
        return ():void => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}