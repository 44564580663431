import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import './index.scss';
import { calculator, toggle } from '../animation/calculator';
import { useWindowSize } from '../../functions/window-size';

const PopinDevis = () => {

    const img = useStaticQuery(graphql`
    {
        imgCheck: file(name: {eq: "checkbox-checked"}) {
          childImageSharp {
            fixed {
              srcWebp
            }
          }
        }
      }
    `);

    const txts = [
        [`Site vitrine`, `1500 €`],
        [`E-commerce`, `2500 €`],
        [`Application`, `1500 €`],
        [`SEO`, `5600 €`],
        [`Push notification`, `4800 €`],
        [`Solution e-commerce`, `4800 €`],
        [`Push notification`, `4800 €`],
        [`Campagne Adwords`, `4800 €`],
    ];

    const windowSize = useWindowSize();

    const [ animation ] = React.useState(calculator);

    React.useEffect(() => {
        if(windowSize.width > 768) {
            animation.initialized = animation.init('#portfolio');
        }
        if(windowSize.width <= 768) {
            animation.close();
            document.getElementById('call-us').classList.add('shown');
            document.getElementById('make-estimate').classList.add('shown');
        }
    }, [windowSize.width]);

    if(windowSize.width > 768) {
        return (
            <>
                <div className="modal popin-hidden" id="modal-devis">
                    <button
                        className="modal-btn_close"
                        onClick={(e) => {
                            e.preventDefault();
                            toggle().classList.add('shown');
                            animation.close();
                            return;
                        }}
                    >
                        X
                    </button>
                    <div className="modal-content" >
                        <h2>
                            <div className="top">Estimez votre</div>
                            <div className="bottom">projet</div>
                        </h2>
                        <Link to="/project" target="_self" className="btn-estimate" title="Project">
                            Commencer
                        </Link>
                    </div>
                    <div className="modal-calculator" >
                        <div className="modal-blur top"></div>
                        <div className="modal-blur bottom"></div>
                        <ul id="calc-list" className="calculator-list">
                            {txts.map((elem, key) => (
                                <li className="calculator-item" key={key}>
                                    <img src={img.imgCheck.childImageSharp.fixed.srcWebp} width="24" height="24"/>
                                    <div className="check"></div>
                                    <span>{elem[0]}</span>
                                    <span>{elem[1]}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div
                    id="popin-toggle"
                    className="ts-lg tw-medium"
                    onClick={(e) => {
                        e.preventDefault();
                        animation.setcanPopinAppear(true);
                        toggle().classList.remove('shown');
                        animation.restart();
                    }}
                >
                    faire un devis
                </div>
            </>
        )
    }
    else if(windowSize.width <= 768) {
        return (
            <div className="mini-devis-cta">
                <div
                    id="call-us"
                    className="ts-lg tw-medium shown"
                >
                    nous appeler
                    <a
                        href="tel:0769818682"
                        className="absolute-link"
                        title="Nous contacter"
                    >
                    </a>
                </div>
                <div
                    id="make-estimate"
                    className="ts-lg tw-medium shown"
                >
                    faire un devis
                    <Link
                        to="/project"
                        className="absolute-link"
                        title="Project"
                    >
                    </Link>
                </div>
            </div>
        )
    }
    else {
        return <></>;
    }
}

export default PopinDevis;